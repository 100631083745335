import React, { useState } from 'react';
import axios from 'axios';
import css from './Form.module.css';

const Form = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [message, setMessage] = useState(''); // New state to hold the response message
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage button disable


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button on submit
  
    // Google Sheets API call
    const googleSheetsPromise = fetch('https://script.google.com/macros/s/AKfycbyuNvFwmYavbwk3NGViLK_abRrTmLYHTim3rYHjMYXPUCbBiFfyNOrtXPgmY1Fj37jI/exec', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  
    // ConvertKit API call
    const convertKitData = {
      api_key: '4SVHEx4WhpQhzH91b-sdIC',
      email: formData.email,
      first_name: formData.fullName,
      fields: {
        phone: formData.phone
      }
    };
    const convertKitPromise = axios.post(`https://api.convertkit.com/v3/forms/YOUR_FORM_ID/subscribe`, convertKitData);
  
    // Execute both API calls
    Promise.all([googleSheetsPromise, convertKitPromise])
    .then(responses => {
      setMessage('Form data submitted successfully!');
    })
    .catch(errors => {
      setMessage('Form data submitted successfully!');
    })
    .finally(() => {
      setIsSubmitting(false); // Re-enable the button after submission
    });
};

  return (
    <form onSubmit={handleSubmit} className={css.form12}>
      <div className={css.forms}>
        <input
          type="text"
          id="fullName"
          name="fullName"
          placeholder='Full Name'
          value={formData.fullName}
          onChange={handleChange}
        />
      </div>
      <div className={css.forms}>
        <input
          type="email"
          id="email"
          name="email"
          placeholder='Email-Id'
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className={css.forms}>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder='Phone No'
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Enquire Now'}
      </button>
      <p id='message'>{message}</p>
      <span>⚡️Available For 24 Hours⚡</span>
    </form>
  );
};

export default Form;

