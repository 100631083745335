import React, { useState } from "react";
import css from './Footer.module.css';
import { NavLink } from 'react-router-dom';
import message from '../../resources/image1.svg';
import phone from '../../resources/Image2.svg';
import time from '../../resources/image3.svg';

const Footer = ({ scrollToSection, refs }) => {
    const [showNav, setshowNav] = useState(false);


    return (
        <div className={css.FooterContainer}>
            <div className={css.firstflex}>
                <div className={css.flexin}>
                    <h3>Address</h3>
                    <p>First Floor,H.No.1-98/11/2/59,</p>
                    <p> Arunodaya Colony,</p>
                    <p>Madhapur R R Dist,</p>
                    <p>Hyderabad-500081</p>
                </div>
                <div className={css.flexin}>
                    <h3>Our Contact</h3>
                    <div className={css.icon}>
                        <div className={css.iconImage}>
                            <img src={message} alt='message' />
                        </div>
                        <h4>www.digitalpicks.in</h4>
                    </div>

                    <div className={css.icon}>
                        <div className={css.iconImage}>
                            <img src={phone} alt='phone' />
                        </div>
                        <h4>+91 7330630258</h4>
                    </div>

                    <div className={css.icon}>
                        <div className={css.iconImage}>
                            <img src={time} alt='Date and Time' />
                        </div>
                        <h4>admin@digitalpicks.in</h4>
                    </div>
                </div>

                <div className={css.flexin}>
                    <h3>Quick Links</h3>
                    <li onClick={() => { scrollToSection(refs.aboutRef); setshowNav(false); }}>About Us</li>


                    <li onClick={() => { scrollToSection(refs.servicesRef); setshowNav(false); }}>Services</li>

                    <li onClick={() => { scrollToSection(refs.workRef); setshowNav(false); }}>Work</li>
                </div>

                <div className={css.flexin}>
                    <h3>Help</h3>
                    <p>Privacy Policy</p>
                </div>

            </div>

            <p className={css.copyright}>© Copyright 2024 All Rights Reserved by <span>Digital Pick</span></p>
        </div>
    )
}

export default Footer