import React from 'react';
import rainbow1 from '../../resources/rainbow1.jpeg';
import rainbow2 from '../../resources/rainbow2.jpeg';
import css from './Association.module.css'


const Association = () => {
  return (
    <div className={css.associationContainer}>
        <h1>In Association with</h1>
        <div className={css.cards}>
            <div className={css.card}>
                <div className={css.rainImage}>
                    <img src={rainbow1} alt='Rainbow Advertising'/>
                </div>
                <h2>Rainbow Advertising</h2>
            </div>

            <div className={css.card}>
                <div className={css.rain2Image}>
                    <img src={rainbow2} alt='Rainbow Advertising'/>
                </div>
                <h2>Sri Achyuta Rainbow Infra</h2>
            </div>
        </div>
    </div>
  )
}

export default Association