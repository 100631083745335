import React from 'react';
import css from './AddServices.module.css'
import webdesign from '../../resources/website-design.png';
import analytics from '../../resources/analytics-insights.png';
import marketing from '../../resources/consultative-approach.png';
import marketing2 from '../../resources/customized-strategies.png';

const AddServices = () => {
  return (
    <div className={css.serviceContainer}>
    <h2>Our Additional Services</h2>

    <div className={css.services}>
        <div className={css.service}>
            <div className={css.serviceImage}>
                <img src={marketing} alt='Search Engine Optimization' />

            </div>

            <div className={css.serviceContent}>
                <h3>Content Marketing</h3>
                <p><span>High-Quality Content Creation:</span> Produce compelling blog posts, articles, and infographics.</p>
                <p><span>Content Strategy and Distribution:</span>  Plan and distribute content to reach your target audience.</p>
                <p><span>Brand Storytelling:</span> Craft a unique narrative that highlights your brand’s values and mission.</p>
            </div>
        </div>

        <div className={css.service}>
            <div className={css.serviceImage}>
                <img src={marketing2} alt='Pay-Per-Click (PPC) Advertising' />

            </div>

            <div className={css.serviceContent}>
                <h3>Email Marketing</h3>
                <p><span>Personalized Campaigns:</span> Create targeted email campaigns that convert.</p>
                <p><span>Automated Sequences:</span> Set up automated workflows to nurture leads.</p>
                <p><span>Performance Tracking:</span> Analyze and optimize your email marketing efforts.</p>
            </div>
        </div>

        <div className={css.service}>
            <div className={css.serviceImage}>
                <img src={webdesign} alt='Social Media Marketing' />
            </div>
            
            <div className={css.serviceContent}>
                <h3>Web Design and Development</h3>
                <p><span>Responsive Design:</span> Ensure your website looks great on all devices.</p>
                <p><span>User-Friendly Interfaces:</span> Create intuitive and engaging user experiences.</p>
                <p><span>E-Commerce Solutions:</span> Develop robust online stores to boost sales.</p>
            </div>
        </div>

        <div className={css.service}>
            <div className={css.serviceImage}>
                <img src={analytics} alt='Search Engine Optimization' />
            </div>
            <div className={css.serviceContent}>
                <h3>Analytics and Reporting</h3>
                <p><span>Data-Driven Insights:</span>Use data to inform your marketing strategies.</p>
                <p><span>Performance Metrics: </span>Track key performance indicators (KPIs) to measure success.</p>
                <p><span>Custom Reports:</span>Receive detailed reports tailored to your business goals.</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default AddServices