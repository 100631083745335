import React from 'react';
import css from './About.module.css';
import about from '../../resources/home_about.png';

const About = ({scrollToSection, refs }) => {
  return (
    <div className={css.aboutContainer}>
        <div className={css.section2}>
            <div className={css.content1}>
            <h1>Who We Are</h1>
             <p>We are a team of passionate digital marketing experts 
            committed to delivering exceptional results. Our expertise 
            spans across various facets of digital marketing, making us 
            a one-stop solution for all your online marketing needs.</p>

            <button onClick={() => scrollToSection(refs.homeRef)}>Book a Discovery Call</button>  
            </div>

            <div className={css.aboutImage}>
              <img src={about} alt='About us'/>
            </div>
        </div>  
    </div>
  )
}

export default About