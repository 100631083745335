import React, { useState } from "react";
import css from './Navbar.module.css';
import logo from '../../resources/DIGITAL PICK final 6 (1).png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = ({ scrollToSection, refs }) => {
  const [showNav, setshowNav] = useState(false);

  return (
    <>
      <div className={css.nav}>

        <div className={css.logo} onClick={() => scrollToSection(refs.homeRef)}>
          <img src={logo} alt='Digitallogo' />
        </div>

        <ul>
          <li onClick={() => scrollToSection(refs.homeRef)} >Home</li>
          <li onClick={() => scrollToSection(refs.aboutRef)}>About Us</li>
          <li onClick={() => scrollToSection(refs.servicesRef)}>Services</li>
          <li onClick={() => scrollToSection(refs.workRef)}>Work</li>
          <li onClick={() => scrollToSection(refs.contactRef)}>Contact Us</li>
        </ul>

        <button onClick={() => scrollToSection(refs.homeRef)}>Get Started Now
          <FontAwesomeIcon icon={faLocationArrow} />
        </button>

      </div>

      <div className={css.navMobile}>
        <div className={css.navigation}>
          <div className={css.logo}>
            <div onClick={() => scrollToSection(refs.homeRef)} >
              <img src={logo} alt="Digitalpick-logo" />
            </div>
          </div>

          <header>
            <GiHamburgerMenu onClick={() => setshowNav(!showNav)} />
            {showNav && (
              <div className={css.sidenav}>
                <ul>
                  <li onClick={() => {scrollToSection(refs.aboutRef); setshowNav(false); }}>About Us</li>


                  <li onClick={() => {scrollToSection(refs.servicesRef); setshowNav(false); }}>Services</li>

                  <li onClick={() => {scrollToSection(refs.workRef); setshowNav(false); }}>Work</li>

                  <li onClick={() => {scrollToSection(refs.contactRef); setshowNav(false); }}>Contact Us</li>

                  <li onClick={() => {scrollToSection(refs.homeRef); setshowNav(false); }}>Get Started</li>
                </ul>

              </div>
            )}
          </header>
        </div>
      </div>
    </>

  )
}

export default Navbar