import React, { useRef } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from "./components/Navbar/Navbar";
import logo from "./resources/logo.svg";
import css from "./App.module.css";
import Home from "../src/components/Home/Home";
import About from "../src/components/About/About";
import Services from "./components/Services/Services";
import AddServices from "../src/components/AddServices/AddServices";
import Choose from "../src/components/Choose/Choose";
import Footer from "./components/Footer/Footer";
import Testimonials from '../src/components/Testimonials/Testimonials';
import Tools from "./components/Tools/Tools";
import Industries from "./components/Industries/Industries";
import Association from "./components/Association/Association";

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const workRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    window.scrollTo({
      top: sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <Router>
      <Navbar
        scrollToSection={scrollToSection}
        refs={{ homeRef, aboutRef, servicesRef, contactRef }}
      />
      <div ref={homeRef}>
        <Home />
      </div>
      <div ref={aboutRef}>
        <About scrollToSection={scrollToSection}
        refs={{ homeRef, aboutRef, servicesRef, contactRef }}/>
        <Choose />
      </div>
     
      <div ref={servicesRef}>
        <Services />
        <AddServices />
      </div>
      
      <div ref={workRef}>
      <Tools/>
      <Industries/>
      <Testimonials/>
      <Association/>
      </div>
     
      <Footer  scrollToSection={scrollToSection}
        refs={{ homeRef, aboutRef, servicesRef, contactRef }}/>
    </Router>
  );
}

export default App;
