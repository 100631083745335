import React from 'react';
import chatgpt from '../../resources/chatgpt.png';
import meta from '../../resources/meta.png';
import buffer from '../../resources/buffer.png';
import hoot from '../../resources/hootsuite.png';
import linkedIn from '../../resources/linked-in-sales-navigator.png';
import zoho from '../../resources/zoho.png';
import hub from '../../resources/hubspot.png';
import send from '../../resources/sendgrid.png';
import sem from '../../resources/semrush.png';
import hot from '../../resources/hotjar.png';
import facebook from '../../resources/facebook.png';
import instagram from '../../resources/Instagram.png';
import google from '../../resources/google_ads.png';
import google2 from '../../resources/Google_Analytics.png';
import linked2 from '../../resources/Linkedin.png';
import youtube from '../../resources/Youtube.png';
import css from './Tools.module.css'

const Tools = () => {
  return (
    <div className={css.toolsContainer}>
        <h1>Tools and Technologies - Our Experties</h1>

        <div className={css.icons}>
          <div className={css.icon}>
            <img src={chatgpt} alt='chatgpt'/>
          </div>
          <div className={css.icon}>
            <img src={meta} alt='meta'/>
          </div>
          <div className={css.icon}>
            <img src={buffer} alt='buffer'/>
          </div>
          <div className={css.icon}>
            <img src={hoot} alt='hoot'/>
          </div>
          <div className={css.icon}>
            <img src={linkedIn} alt='linked In'/>
          </div>
          <div className={css.icon}>
            <img src={zoho} alt='zoho'/>
          </div>
          <div className={css.icon}>
            <img src={hub} alt='hubspot'/>
          </div>
          <div className={css.icon}>
            <img src={send} alt='sendgrid'/>
          </div>
          <div className={css.icon}>
            <img src={sem} alt='sem Rush'/>
          </div>
          <div className={css.icon}>
            <img src={hot} alt='hot jar'/>
          </div>
          <div className={css.icon}>
            <img src={facebook} alt='facebook'/>
          </div>
          <div className={css.icon}>
            <img src={instagram} alt='instagram'/>
          </div>
          <div className={css.icon}>
            <img src={google} alt='Google'/>
          </div>
          <div className={css.icon}>
            <img src={google2} alt='Google Analytics'/>
          </div>
          <div className={css.icon}>
            <img src={linked2} alt='LinkedIn'/>
          </div>
          <div className={css.icon}>
            <img src={youtube} alt='Youtube'/>
          </div>
          
        </div>
    </div>
  )
}

export default Tools