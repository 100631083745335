import React from 'react';
import css from './Choose.module.css';
import quality from '../../resources/quality.svg';
import insights from '../../resources/insights.svg';
import rigorous from '../../resources/rigorous.svg';
import focussed from '../../resources/focused.svg';

const Choose = () => {
  return (
    <div className={css.chooseContainer}>
        <h1>Why Choose Us?</h1>

        <div className={css.choose}>
            <div className={css.chooseone}>
                <div className={css.choosepic}>
                    <img src={quality} alt='quality'/>
                </div>
                <div className={css.choosecontent}>
                    <h2>Expertise</h2>
                    <p>Our team has years of experience in the digital marketing field.</p>
                </div>
            </div>

            <div className={css.chooseone}>
                <div className={css.choosepic}>
                    <img src={insights} alt='insights'/>
                </div>
                <div className={css.choosecontent}>
                    <h2>Customized Solutions</h2>
                    <p>We tailor our strategies to meet your unique business needs.</p>
                </div>
            </div>

            <div className={css.chooseone}>
                <div className={css.choosepic}>
                    <img src={rigorous} alt='rigorous'/>
                </div>
                <div className={css.choosecontent}>
                    <h2>Proven Results</h2>
                    <p>We have a track record of delivering measurable results.</p>
                </div>
            </div>

            <div className={css.chooseone}>
                <div className={css.choosepic}>
                    <img src={focussed} alt='focussed'/>
                </div>
                <div className={css.choosecontent}>
                    <h2>Customer-Centric Approach</h2>
                    <p>Your success is our priority. We work closely with you to achieve your goals.</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Choose