import React from 'react';
import css from '../Home/Home.module.css'
import Form from '../Form/Form';


const Home = () => {


    return (
        <div>
            <div className={css.HomeContainer}>
            <div className={css.section1}>
                <div className={css.content}>
                    <h1>Transforming Your Digital Presence</h1>
                    <p>At Digital Pick, we are dedicated to revolutionizing the way businesses engage with their 
                        audience. Our innovative strategies and data-driven 
                        approach ensure that your brand stands out in the 
                        digital landscape.</p>
                </div>
                <div className={css.form1}>
                    <h3>Quick Enquiry</h3>
                  <Form/>
                </div>
            </div>
            </div>
           
        </div>
    )
}

export default Home