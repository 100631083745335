import React from 'react';
import css from './Services.module.css';
import seo from '../../resources/SEO-optimization.png';
import ads from '../../resources/ads-management.png';
import analytics from '../../resources/analytics-insights.png';
import smo from '../../resources/social-media-marketing.png';
import quality from '../../resources/quality.svg';
import billboard from '../../resources/billboard.jpeg'

const Services = () => {
    return (
        <div className={css.serviceContainer}>
            <h2>Our Services</h2>

            <div className={css.services}>
                <div className={css.service}>
                    <div className={css.serviceImage}>
                        <img src={seo} alt='Search Engine Optimization' />
                    </div>

                    <div className={css.serviceContent}>
                        <h3>Search Engine Optimization(SEO)</h3>
                        <p><span>Increase Visibility:</span> Improve your website’s ranking on search engines.</p>
                        <p><span>Targeted Keyword Strategies:</span> Identify and implement the most effective keywords.</p>
                        <p><span>On-Page and Off-Page Optimization:</span> Enhance both the content and technical aspects of your website.</p>
                    </div>
                </div>

                <div className={css.service}>
                    <div className={css.serviceImage}>
                        <img src={ads} alt='Pay-Per-Click (PPC) Advertising' />
                    </div>

                    <div className={css.serviceContent}>
                        <h3>Pay-Per-Click (PPC) Advertising</h3>
                        <p><span>Cost-Effective Campaigns:</span> Maximize your advertising budget with strategic ad placements.</p>
                        <p><span>Detailed Analytics and Reporting:</span> Monitor performance and adjust strategies in real-time.</p>
                        <p><span>Customized Ad Strategies:</span> Tailor campaigns to your specific business goals and audience.</p>
                    </div>
                </div>

                <div className={css.service}>
                    <div className={css.serviceImage}>
                        <img src={billboard} alt='Social Media Marketing' />
                    </div>

                    <div className={css.serviceContent}>
                        <h3>Outdoor Advertising</h3>
                        <p><span>Billboards:</span> Large, eye-catching ads placed along highways, roads, and in busy urban areas.</p>
                        <p><span>Street Furniture Ads:</span> Ads on bus shelters, kiosks, benches, and newsstands.</p>
                        <p><span>Place-Based Media: </span> Advertisements in specific locations such as shopping malls, airports, stadiums, and other high-traffic venues.</p>
                    </div>
                </div>

                <div className={css.service}>
                    <div className={css.serviceImage}>
                        <img src={smo} alt='Social Media Marketing' />
                    </div>

                    <div className={css.serviceContent}>
                        <h3>Social Media Marketing</h3>
                        <p><span>Engaging Content Creation:</span> Develop content that resonates with your audience.</p>
                        <p><span>Audience Engagement and Growth:</span> Build and nurture a loyal following.</p>
                        <p><span>Comprehensive Management:</span> Oversee all aspects of your social media presence.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Services


 
