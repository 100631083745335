import React from 'react';
import css from './Industries.module.css';
import ecom from '../../resources/e-commerce.jpg';
import fashion from '../../resources/fashion.jpg';
import Health from '../../resources/healthcare.jpg';
import information from '../../resources/it.jpg';
import telecom from '../../resources/telecom.jpg';
import bfsi from '../../resources/bfsi.jpg';
import fmcg from '../../resources/fmcg.jpg';
import manufacture from '../../resources/manufacturing.jpg';

const Industries = () => {
  return (
    <div className={css.IndustryContainer}>
        <h2>Industries we serve</h2>
        <div className={css.Industries}>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={ecom} alt='e-commerce'/>
            </div>
            <h3>E-commerce</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={fashion} alt='Fashion & Beauty'/>
            </div>
            <h3>Fashion & Beauty</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={Health} alt='Healthcare'/>
            </div>
            <h3>Healthcare</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={information} alt='Information Technology'/>
            </div>
            <h3>Information Technology</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={telecom} alt='Telecom'/>
            </div>
            <h3>Telecom</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={bfsi} alt='bfsi'/>
            </div>
            <h3>BFSI</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={fmcg} alt='fmcg'/>
            </div>
            <h3>FMCG</h3>
        </div>
        <div className={css.Industry}>
            <div className={css.IndustryImage}>
                <img src={manufacture} alt='Manufacturing'/>
            </div>
            <h3>Manufacturing</h3>
        </div>
        </div>
    </div>
  )
}

export default Industries