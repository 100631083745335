import React from 'react';
import css from './Testimonials.module.css';

const Testimonials = () => {
    return (
        <div className={css.clientContainer}>
            <h1>Client Testimonials</h1>

            <div className={css.clients}>

                <div className={css.clientContent}>
                    <span>★★★★★</span>
                    <p>"Working with Digital Pick has been a
                        game-changer for our business. Their strategies
                        have significantly increased our online visibility and
                        engagement."</p>
                    <h4>-Geetha</h4>
                    <h6>Digital Lead</h6>
                </div>

                <div className={css.clientContent}>
                    <span>★★★★★</span>
                    <p>"The team at Digital Pick is knowledgeable, 
                        professional, and results-driven. We highly 
                        recommend their services!"</p>
                    <h4>-Kalyan</h4>
                    <h6>Marketing Manager</h6>
                </div>

            </div>
        </div>
    )
}
export default Testimonials;